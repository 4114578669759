import React, {Component} from 'react';

class ContactBoots extends Component {
    render() {
        return (
            <div className='container'>
                Contact page
            </div>
        );
    }
}

export default ContactBoots;