import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./components/App";
import store from "./redux/store";
import "./index.css";
import { funcIsMob } from "./services/isMobile"

ReactDOM.render(
    <Provider store={store}>
    <App />
    </Provider>,
  document.getElementById("root")
);

funcIsMob()