export const middleImg = [
    { img: "https://i.ibb.co/wyp88RL/science-wallpapers-hd-group-hd-wallpapers-pinterest-wallpaper-HD-Wallpaper-for-PC-Desktop-Background.jpg" },
    { img: "https://i.ibb.co/7N083Gd/image.jpg" },
    { img: "https://i.ibb.co/3sJSLQK/1.jpg" },
    { img: "https://i.ibb.co/6WJrHN5/2.jpg" },
    { img: "https://i.ibb.co/BrpPRxg/3.jpg" },
    { img: "https://i.ibb.co/WsX8KbQ/4.jpg" },
    { img: "https://i.ibb.co/12bgwf2/5.jpg" },
    { img: "https://i.ibb.co/0y6brzS/6.jpg" },
    { img: "https://i.ibb.co/gMx4xNd/7.jpg" },
    { img: "https://i.ibb.co/XCW9z5d/8.jpg" },
    { img: "https://i.ibb.co/4t6LpTL/9.jpg" },
    { img: "https://i.ibb.co/C5YwtVX/10.jpg" }
];













