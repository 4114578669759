const listBurger = [
    {"name": "О комапании", "link": "/about", "id":"about"},
    {"name": "Сертификаты", "link": "/certificate", "id":"certificate"},
    {"name": "Сотрудничество", "link": "/cooperation", "id":"cooperation"},
    {"name": "Отзывы", "link": "/reviews", "id":"reviews"},
    {"name": "Видео", "link": "/video", "id":"video"},
    {"name": "Гарантия", "link": "/warranty", "id":"warranty"},
    {"name": "Контакты", "link": "/contact", "id":"contact"}
]

export default listBurger